import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import External from "@/router/external";
import Login from "@/views/pages/Login.vue";
import NotFound from "@/views/pages/404.vue";
import { store } from "@/store";
import AudienceRecorder from "@/views/pages/AudienceRecorder.vue";
import AudienceRecordList from "@/views/pages/AudienceRecordList.vue";
import AudiencesPage from "@/views/pages/AudiencesPage.vue";
import { USER_STORE } from "@/store/modules/user/constants";

const routes: Array<RouteRecordRaw> = [
  {
    name: "Login",
    path: "/login",
    component: Login,
  },
  {
    name: "Logout",
    path: "/logout",
    component: Login,
    beforeEnter: async () => {
      await store.dispatch(USER_STORE.ACTIONS.LOGOUT);
      return true;
    },
  },
  {
    name: "Dashboard",
    path: "/",
    redirect: { name: "Audiences" },
  },
  {
    name: "Audiences",
    path: "/audiences",
    component: AudiencesPage,
  },
  {
    name: "AudienceRecorder",
    path: "/audience/recorder",
    component: AudienceRecorder,
    props: (route) => {
      return route.params;
    },
  },
  {
    name: "AudienceListRecords",
    path: "/audience/:id/records",
    component: AudienceRecordList,
    props: (route) => {
      return route.params;
    },
  },
  ...External,
  {
    path: "/:catchAll(.*)",
    component: NotFound,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
