import { MutationTree } from "vuex";
import {
  UserMutationsTypes,
  IUserStore,
  ILogin,
} from "@/store/modules/user/interfaces";
import { USER_STORE } from "@/store/modules/user/constants";
import { IWebexMeeting, IWebexUser } from "@/interfaces/IWebex";

export const mutations: MutationTree<IUserStore> & UserMutationsTypes = {
  [USER_STORE.MUTATIONS.LOGIN](state: IUserStore, payload: { user: ILogin; meeting: IWebexMeeting }) {
    state.user = payload.user.user;
    state.token = payload.user.token;
    state.meeting = payload.meeting;
  },
  [USER_STORE.MUTATIONS.LOGOUT](state: IUserStore) {
    state.user = undefined;
    state.token = undefined;
    state.meeting = undefined;
  },
};
