<script lang="ts" setup>
import ToastNotification from "@/components/layout/ToastNotification.vue";
import { useStore } from "@/use/useStore";
import { ROOT_STORE } from "@/store/modules/root/constants";
import { computed } from "vue";
import { ModalsContainer } from 'vue-final-modal'

const store = useStore();
const theme = computed(() => store.getters[ROOT_STORE.GETTERS.THEME]);
</script>
<template>
  <div :class="theme.name">
    <toast-notification></toast-notification>
    <router-view></router-view>
    <ModalsContainer />
  </div>
</template>
