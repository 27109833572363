<script setup lang="ts">
import { ChevronRightIcon, HomeIcon } from "@heroicons/vue/solid";
import { computed } from "vue";
import { ROOT_STORE } from "@/store/modules/root/constants";
import { useStore } from "@/use/useStore";

const store = useStore();

const pages = computed(
  () => store.getters[ROOT_STORE.GETTERS.BREADCRUMBS_ITEMS]
);
</script>
<template>
  <div class="max-w-full mx-auto px-4 sm:px-6 md:px-8 py-6" v-if="pages.length">
    <nav class="flex" aria-label="Breadcrumb">
      <ol class="flex items-center space-x-4">
        <li>
          <div>
            <a href="#" class="text-gray-400 hover:text-gray-500">
              <HomeIcon class="flex-shrink-0 h-5 w-5" aria-hidden="true" />
              <span class="sr-only">Home</span>
            </a>
          </div>
        </li>
        <li v-for="page in pages" :key="page.name">
          <div class="flex items-center">
            <ChevronRightIcon
              class="flex-shrink-0 h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
            <a
              :href="page.href"
              class="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
              :aria-current="page.current ? 'page' : undefined"
              >{{ page.name }}</a
            >
          </div>
        </li>
      </ol>
    </nav>
  </div>
</template>
