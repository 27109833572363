import { Module } from "vuex";
import { IRootState } from "@/store/modules/root/interfaces";
import { IUserStore } from "@/store/modules/user/interfaces";
import { getters } from "@/store/modules/user/getters";
import { actions } from "@/store/modules/user/actions";
import { mutations } from "@/store/modules/user/mutations";
import { state } from "@/store/modules/user/state";

// Module
const user: Module<IUserStore, IRootState> = {
  state,
  getters,
  mutations,
  actions,
};

export default user;
