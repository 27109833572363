import { createStore } from "vuex";
import { IRootState } from "@/store/modules/root/interfaces";

import root from "@/store/modules/root";

import { RootStoreModuleTypes } from "@/store/modules/root/types";
import { UserStoreModuleTypes } from "@/store/modules/user/types";
import { VideoPlayerStoreModuleTypes } from "@/store/modules/videoPlayer/types";

export const store = createStore<IRootState>(root);

type StoreModules = {
  userModule: UserStoreModuleTypes;
  videoPlayer: VideoPlayerStoreModuleTypes;
  root: RootStoreModuleTypes;
};

export type Store = UserStoreModuleTypes<Pick<StoreModules, "userModule">> &
  VideoPlayerStoreModuleTypes<Pick<StoreModules, "videoPlayer">> &
  RootStoreModuleTypes<Pick<StoreModules, "root">>;
