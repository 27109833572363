export enum UserGetters {
  USER = "USER",
  LOGGED_USER = "LOGGED_USER",
  IS_LOGGED = "IS_LOGGED",
  MEETING = "MEETING"
}
export enum UserMutations {
  LOGIN = "LOGIN",
  LOGOUT = "LOGOUT",
}
export enum UserActions {
  LOGIN = "LOGIN",
  RECOVERY_PASS = "RECOVERY_PASS",
  WEBEX_PASS_THROUGH = "WEBEX_PASS_THROUGH",
  CHECK = "CHECK",
  LOGOUT = "LOGOUT",
}
