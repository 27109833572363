import { ROOT_STORE } from "@/store/modules/root/constants";
import { GetterTree } from "vuex";
import {
  INavigationData,
  IRootGettersTypes,
  IRootState,
  ITheme,
  IToast,
} from "@/store/modules/root/interfaces";
import { RouteLocationNormalized } from "vue-router";

export const getters: GetterTree<IRootState, IRootState> & IRootGettersTypes = {
  [ROOT_STORE.GETTERS.SIDE_NAVIGATION_ITEMS]: (
    state: IRootState
  ): INavigationData[] => {
    return state.sideNavigationItems;
  },
  [ROOT_STORE.GETTERS.SIDE_NAVIGATION_OPEN]: (state: IRootState): boolean => {
    return state.sideNavigationOpen;
  },
  [ROOT_STORE.GETTERS.REDIRECT_ROUTE]: (
    state: IRootState
  ): RouteLocationNormalized | undefined => {
    return state.redirectRoute;
  },
  [ROOT_STORE.GETTERS.BREADCRUMBS_ITEMS]: (
    state: IRootState
  ): INavigationData[] => {
    return state.breadcrumbsItems;
  },
  [ROOT_STORE.GETTERS.THEME]: (state: IRootState): ITheme => {
    return state.theme;
  },
  [ROOT_STORE.GETTERS.TOASTERS]: (state: IRootState): IToast[] => {
    return state.toasters;
  },
};
