import { RouteRecordRaw } from "vue-router";
import WebexStart from "@/views/pages/WebexStart.vue";

const Online = {
  template: "<h2>On-line</h2>",
};

const external: Array<RouteRecordRaw> = [
  {
    name: "External.WebexStart",
    path: "/start",
    component: WebexStart,
  },
  {
    name: "HealthCheck",
    path: "/health-check",
    component: Online,
  },
];

export default external;
