import { MutationTree } from "vuex";
import {
  IVideoPlayer,
  VideoPlayerMutationsTypes,
} from "@/store/modules/videoPlayer/interfaces";
import { VIDEO_PLAYER_STORE } from "@/store/modules/videoPlayer/constants";
import videojs from "video.js";

export const mutations: MutationTree<IVideoPlayer> & VideoPlayerMutationsTypes =
  {
    [VIDEO_PLAYER_STORE.MUTATIONS.SET_INSTANCE](
      state: IVideoPlayer,
      payload: videojs.Player
    ) {
      state.instance = payload;
    },
    [VIDEO_PLAYER_STORE.MUTATIONS.PLAYER_READY](
      state: IVideoPlayer,
      payload: boolean
    ) {
      state.playerReady = payload;
    },
    [VIDEO_PLAYER_STORE.MUTATIONS.SET_PLAYER_ERROR](
      state: IVideoPlayer,
      payload: string
    ) {
      state.playerError = payload;
    },
    [VIDEO_PLAYER_STORE.MUTATIONS.SET_SECONDS_DURATION](
      state: IVideoPlayer,
      payload: number
    ) {
      state.secondsDuration = payload;
    },
    [VIDEO_PLAYER_STORE.MUTATIONS.SET_SECONDS_CURRENT](
      state: IVideoPlayer,
      payload: number
    ) {
      state.secondsCurrent = payload;
    },
    [VIDEO_PLAYER_STORE.MUTATIONS.SET_LOADING_PERCENTAGE](
      state: IVideoPlayer,
      payload: number
    ) {
      state.loadingPercentage = payload;
    },
  };
