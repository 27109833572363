import { MutationTree } from "vuex";
import {
  RootMutationsTypes,
  IRootState,
  INavigationData,
  IToast,
  ITheme,
} from "@/store/modules/root/interfaces";
import { ROOT_STORE } from "@/store/modules/root/constants";
import { RouteLocationNormalized } from "vue-router";

export const mutations: MutationTree<IRootState> & RootMutationsTypes = {
  [ROOT_STORE.MUTATIONS.SIDE_NAVIGATION_ITEMS](
    state,
    payload: INavigationData[]
  ) {
    state.sideNavigationItems = payload;
  },
  [ROOT_STORE.MUTATIONS.SIDE_NAVIGATION_OPEN](state, payload: boolean) {
    state.sideNavigationOpen = payload;
  },
  [ROOT_STORE.MUTATIONS.REDIRECT_ROUTE](
    state,
    payload: RouteLocationNormalized | undefined
  ) {
    state.redirectRoute = payload;
  },
  [ROOT_STORE.MUTATIONS.BREADCRUMBS_ITEMS](
    state,
    payload: Array<INavigationData>
  ) {
    state.breadcrumbsItems = payload;
  },
  [ROOT_STORE.MUTATIONS.THEME](state: IRootState, theme: ITheme) {
    state.theme = theme;
  },
  [ROOT_STORE.MUTATIONS.TOASTER_ADD](state: IRootState, toast: IToast) {
    state.toasters.push(toast);
  },
  [ROOT_STORE.MUTATIONS.TOASTER_REMOVE](state: IRootState, toast: IToast) {
    const idx = state.toasters.findIndex((item) => (item.id = toast.id));
    if (idx) {
      state.toasters.splice(idx);
    }
  },
};
