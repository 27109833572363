<script setup lang="ts">
import AppTemplate from "@/views/templates/AppTemplate.vue";
import PanelUi from "@/components/ui/PanelUi.vue";
import SpinnerUi from "@/components/ui/SpinnerUi.vue";
import { API } from "@/service/API";
import { debounce } from "debounce";
import { apiErrorHandlers } from "@/service/utils";
import { onMounted, Ref, ref, watch } from "vue";
import { IAudience, IAudiencesResponse } from "@/interfaces/IAudience";
import AudiencesTable from "@/components/AudiencesTable.vue";
import { IPagination } from "@/interfaces/Commons";
import UiPagination from "@/views/UiPagination.vue";
import { Status, IStatusLabel } from "@/enums/Audience";
import Multiselect from "vue-multiselect";

import { i18n } from "@/main";

const statusOptions = [
  {
    id: Status.WAITING,
    label: i18n.global.t("audience.status." + IStatusLabel[Status.WAITING]),
  },
  {
    id: Status.IN_PROGRESS,
    label: i18n.global.t("audience.status." + IStatusLabel[Status.IN_PROGRESS]),
  },
  {
    id: Status.PUBLISHED,
    label: i18n.global.t("audience.status." + IStatusLabel[Status.PUBLISHED]),
  },
];

let filter = ref({
  process_number: "",
  status: [],
  date: new Date(),
});

let isSearching = ref(false);

let page = ref(0);
let pagination: Ref<IPagination | undefined> = ref();

let audiences: Ref<IAudience[] | []> = ref([]);
async function getAudiences(resetPage = false) {
  isSearching.value = true;

  const day = filter.value.date.getDate();
  const month = (filter.value.date.getMonth() + 1).toString().padStart(2, "0");
  const year = filter.value.date.getFullYear();

  let params = `?date=${encodeURI(`${year}-${month}-${day}`)}&`;
  if (filter.value.process_number.length) {
    params += `number=${filter.value.process_number}&`;
  }

  if (filter.value.status.length) {
    params += `status=${encodeURIComponent(
      JSON.stringify(filter.value.status.map((item) => item.id))
    )}&`;
  }

  if (page.value && !resetPage) {
    params += `page=${page.value}&`;
  }

  await API.http
    .get<IAudiencesResponse>(`/audiences/${params}`)
    .then((response) => {
      audiences.value = response.data.list;
      pagination.value = response.data.pagination;

      isSearching.value = false;
    })
    .catch(apiErrorHandlers);
  isSearching.value = false;
}

function applyFilter() {
  getAudiences(true);
}
function clearFilter() {
  filter.value = {
    process_number: "",
    status: [],
    date: new Date(),
  };
  page.value = 0;
  getAudiences();
}

onMounted(() => {
  getAudiences();
});

watch(
  () => page.value,
  debounce(() => {
    getAudiences();
  }, 500)
);

const formatDate = (date: Date) => {
  const day = date.getDate();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear();

  return `${day}/${month}/${year}`;
};
</script>

<template>
  <app-template>
    <div class="container mx-auto pt-8 p-2">
      <panel-ui
        class="bg-gray-100 w-full max-w-screen-2xl mb-5"
        :title="$t('audience.table.title')"
      >
        <div class="flex flex-col lg:flex-row mt-0 sm:mt-4 mb-8 w-full gap-3">
          <div class="w-12/12 lg:w-5/12">
            <input
              id="num_process"
              name="process_number"
              type="text"
              v-model="filter.process_number"
              @keyup.enter="applyFilter"
              class="appearance-none block w-full px-2 py-2 border border-gray-300 focus:ring-0 focus:border-gray-300 shadow-sm placeholder-gray-300 text-gray-600 rounded-md text-sm"
              :placeholder="$t('form.num_process')"
            />
          </div>
          <div class="flex w-full gap-4 w-12/12 lg:w-6/12 self-end">
            <div class="w-6/12">
              <Multiselect
                id="audience-participant"
                v-model="filter.status"
                :options="statusOptions"
                :multiple="true"
                :close-on-select="true"
                :placeholder="$t('audience.table.status')"
                label="label"
                track-by="id"
                :selectLabel="$t('multiselect.select')"
                :deselectLabel="$t('multiselect.deselect')"
                :selectedLabel="$t('multiselect.selected')"
              >
              </Multiselect>
            </div>
            <div class="w-6/12">
              <vue-date-picker
                v-model="filter.date"
                class="rounded-md border-gray-300 focus:ring-0 focus:border-gray-300 shadow-sm text-gray-600"
                :format="formatDate"
                :auto-apply="true"
                :enable-time-picker="false"
                :clearable="false"
                locale="pt-BR"
              />
            </div>
          </div>
          <div class="flex w-full gap-4 self-end w-12/12 lg:w-4/12">
            <div class="w-6/12">
              <button
                @click="clearFilter"
                class="relative w-full justify-center py-2 px-3 text-sm font-medium rounded-md text-white bg-gray-400 border border-gray-400 hover:bg-gray-500"
              >
                {{ $t("button.clear") }}
              </button>
            </div>
            <div class="w-6/12">
              <button
                @click="applyFilter"
                class="relative w-full justify-center py-2 px-3 text-sm font-medium rounded-md text-white theme-bg-color border theme-border-color hover:theme-bg-color-darker"
              >
                {{ $t("button.search") }}
              </button>
            </div>
          </div>
        </div>

        <div
          v-if="isSearching"
          class="p-4 w-full rounded-lg bg-white flex items-center justify-center h-72"
        >
          <div class="bg-gray-200 p-4 rounded-lg h-14">
            <spinner-ui class="h-5 w-5 float-left mr-3" />
            {{ $t("loading") }}
          </div>
        </div>
        <audiences-table :audiences="audiences" v-if="!isSearching" />
        <ui-pagination
          class="mt-4"
          :pagination="pagination"
          v-if="pagination !== undefined && audiences.length"
          v-model="page"
        />
      </panel-ui>
    </div>
  </app-template>
</template>
