<script setup lang="ts">
import EmptyTemplate from "@/views/templates/EmptyTemplate.vue";
import { onMounted, Ref, ref } from "vue";
import { IWebexMeeting, IWebexUser } from "@/interfaces/IWebex";
import { useStore } from "@/use/useStore";
import { USER_STORE } from "@/store/modules/user/constants";
import LoadingDialog from "@/components/ui/LoadingDialog.vue";
import Application from '@webex/embedded-app-sdk';

const store = useStore();



onMounted(async () => {
  const webexApp = new Application;
  //const webexApp = new window.webex.Application();

  let webexUser: Ref<IWebexUser | undefined> = ref();
  let webexMeeting: Ref<IWebexMeeting | undefined> = ref();
  function getErrorMessage(errorCode: number): string {
    switch (errorCode) {
      case 0: return "SUCCESS";
      case 1: return "GENERIC_ERROR";
      case 2: return "INVALID_ARGUMENT";
      case 3: return "EVENT_ALREADY_REGISTERED";
      case 4: return "EVENT_UNKNOWN";
      case 5: return "BAD_CONTEXT";
      case 6: return "NOT_SUPPORTED";
      case 7: return "NOT_INITIALIZED";
      case 8: return "ACCESS_DENIED";
      case 9: return "TIMED_OUT";
      default: return "UNKNOWN_ERROR";
    }
  }
  console.log("webexMeeting", webexMeeting.value);
  //console.log("webexApp", webexApp);
  //logger("webexApp", {meeting: webexApp.application.isReady});

 
  //logger("webexApp", {meeting: webexApp.context.getMeeting()});
   try {
    await webexApp.onReady();
    await logger("Webex SDK", { message: "SDK pronto para uso" });


    const meetingData = await webexApp.context.getMeeting();
    webexMeeting.value = {
    endTime: meetingData.endTime || null,
    id: meetingData.id || null,
    instanceId: meetingData.instanceId || null,
    meetingType: meetingData.meetingType || null,
    startTime: meetingData.startTime || null,
    title: meetingData.title || null,
    url: meetingData.url || null,
    };
    const userData = await webexApp.application.states.user;
    if (userData !== null) {
      webexUser.value = {
      id: userData.id || null,
      orgId: userData.orgId || null,
      email: userData.email || null,
      displayName: userData.displayName || null,
      token: userData.token || null,
    };
      await logger("Webex User", { message: webexUser.value });
    }
    await logger("webexMeeting", { message: webexMeeting.value });


    if (webexUser.value !== undefined && webexMeeting.value !== undefined) {
      store.dispatch(USER_STORE.ACTIONS.WEBEX_PASS_THROUGH, {
        user: webexUser.value,
        meeting: webexMeeting.value,
      });
    }
  } catch (error) {
    logger("Erro", { message: error });
  } 

  /* webexApp
    .onReady()
    .then(async () => {
      logger("teste", {});
      console.log("webexMeeting");
      webexMeeting.value = await webexApp.context.getMeeting();
      webexUser.value = await webexApp.context.getUser();
      console.log("webexMeeting", webexMeeting.value);
      console.log("webexUser", webexUser.value);
      if (webexUser.value !== undefined && webexMeeting.value !== undefined) {
        store.dispatch(USER_STORE.ACTIONS.WEBEX_PASS_THROUGH, {
          user: webexUser.value,
          meeting: webexMeeting.value,
        });
      }
    })
    .catch((error: number) => {
      const errorMessage = getErrorMessage(error);
      logger("Error with code: ", {errorMessage});
    }); */
});

function logger(type: string, data: object) {
  const ul = document.getElementById("consoleElement");
  const li = document.createElement("li");
  const payload = document.createTextNode(`${type}: ${JSON.stringify(data)}`);
  li.appendChild(payload);
  ul?.prepend(li);
}
</script>
<template>
  <empty-template>
    <div class="min-h-full py-12 px-4 sm:px-6 lg:px-8">
      <div>
        <!-- <loading-dialog :show="true" text="Iniciando" /> -->
        <h2 class="mt-10">Webex</h2>
        <ul
          id="consoleElement"
          class="border-1 border-color-gray"
          v-html="data"
        ></ul>
      </div>
    </div>
  </empty-template>
</template>
