import { VIDEO_PLAYER_STORE } from "@/store/modules/videoPlayer/constants";
import { GetterTree } from "vuex";
import { IRootState } from "@/store/modules/root/interfaces";
import {
  IVideoPlayer,
  IVideoPlayerGettersTypes,
} from "@/store/modules/videoPlayer/interfaces";
import videojs from "video.js";

export const getters: GetterTree<IVideoPlayer, IRootState> &
  IVideoPlayerGettersTypes = {
  [VIDEO_PLAYER_STORE.GETTERS.INSTANCE]: (
    state: IVideoPlayer
  ): videojs.Player | null => {
    return state.instance;
  },
  [VIDEO_PLAYER_STORE.GETTERS.PLAYER_READY]: (state: IVideoPlayer): boolean =>
    state.playerReady,
  [VIDEO_PLAYER_STORE.GETTERS.PLAYER_ERROR]: (
    state: IVideoPlayer
  ): string | null => state.playerError,
  [VIDEO_PLAYER_STORE.GETTERS.SECONDS_DURATION]: (
    state: IVideoPlayer
  ): number => state.secondsDuration,
  [VIDEO_PLAYER_STORE.GETTERS.SECONDS_CURRENT]: (state: IVideoPlayer): number =>
    state.secondsCurrent,
  [VIDEO_PLAYER_STORE.GETTERS.LOADING_PERCENTAGE]: (
    state: IVideoPlayer
  ): number => state.loadingPercentage,
};
