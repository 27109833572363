import { Module, ModuleTree } from "vuex";
import { IRootState } from "@/store/modules/root/interfaces";
import { getters } from "@/store/modules/root/getters";
import { actions } from "@/store/modules/root/actions";
import { mutations } from "@/store/modules/root/mutations";
import { state } from "@/store/modules/root/state";
import userModule from "@/store/modules/user";
import videoPlayerModule from "@/store/modules/videoPlayer";

// Modules
const modules: ModuleTree<IRootState> = {
  userModule,
  videoPlayerModule,
};

const root: Module<IRootState, IRootState> = {
  state,
  getters,
  mutations,
  actions,
  modules,
};

export default root;
