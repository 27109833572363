import { ActionContext, DispatchOptions } from "vuex";
import { ROOT_STORE } from "@/store/modules/root/constants";
import { RenderFunction } from "vue";
import { RouteLocationNormalized } from "vue-router";
import { StoreActions } from "@/store/interfaces";

export interface INavigationChildrenData {
  name: string;
  href: string;
}
export interface IToast {
  id: number;
  title: string;
  message: string;
  type: ToastType;
}

export enum ToastType {
  success = "success",
  warning = "warning",
  error = "error",
}

export interface INavigationData {
  id?: number;
  name: string;
  href?: string;
  slug?: string;
  icon?: RenderFunction;
  current: boolean;
  children?: Array<INavigationData>;
  url?: string;
  menu_icon_class?: string;
}

export interface IRootState {
  sideNavigationItems: Array<INavigationData>;
  sideNavigationOpen: boolean;
  redirectRoute?: RouteLocationNormalized;
  breadcrumbsItems: Array<INavigationData>;
  theme: ITheme;
  toasters: Array<IToast>;
}

export interface ITheme {
  name: string;
  logo: string;
}

export interface IRootGettersTypes {
  [ROOT_STORE.GETTERS.THEME](state: IRootState): ITheme;
  [ROOT_STORE.GETTERS.TOASTERS](state: IRootState): IToast[];
  [ROOT_STORE.GETTERS.SIDE_NAVIGATION_ITEMS](
    state: IRootState
  ): Array<INavigationData>;
  [ROOT_STORE.GETTERS.SIDE_NAVIGATION_OPEN](state: IRootState): boolean;
  [ROOT_STORE.GETTERS.REDIRECT_ROUTE](
    state: IRootState
  ): RouteLocationNormalized | undefined;
  [ROOT_STORE.GETTERS.BREADCRUMBS_ITEMS](
    state: IRootState
  ): Array<INavigationData>;
}

export type RootMutationsTypes<S = IRootState> = {
  [ROOT_STORE.MUTATIONS.SIDE_NAVIGATION_ITEMS](
    state: IRootState,
    payload: Array<INavigationData>
  ): void;
  [ROOT_STORE.MUTATIONS.SIDE_NAVIGATION_OPEN](
    state: IRootState,
    payload: boolean
  ): void;
  [ROOT_STORE.MUTATIONS.REDIRECT_ROUTE](
    state: IRootState,
    payload: RouteLocationNormalized | undefined
  ): void;
  [ROOT_STORE.MUTATIONS.BREADCRUMBS_ITEMS](
    state: IRootState,
    payload: Array<INavigationData>
  ): void;
  [ROOT_STORE.MUTATIONS.THEME](state: S, payload: ITheme): void;
  [ROOT_STORE.MUTATIONS.TOASTER_ADD](state: S, payload: IToast): void;
  [ROOT_STORE.MUTATIONS.TOASTER_REMOVE](state: S, payload: IToast): void;
};

type AugmentedActionContextRoot = {
  commit<K extends keyof RootMutationsTypes>(
    key: K,
    payload: Parameters<RootMutationsTypes[K]>[1]
  ): ReturnType<RootMutationsTypes[K]>;
} & Omit<ActionContext<IRootState, IRootState>, "commit"> & {
    dispatch<K extends keyof StoreActions>(
      key: K,
      payload?: Parameters<StoreActions[K]>[1],
      options?: DispatchOptions
    ): ReturnType<StoreActions[K]>;
  };

export interface RootActionsTypes {
  [ROOT_STORE.ACTIONS.SIDE_NAVIGATION_CURRENT](
    { dispatch }: AugmentedActionContextRoot,
    payload: string | undefined
  ): void;

  [ROOT_STORE.ACTIONS.SIDE_NAVIGATION_OPEN](
    { dispatch }: AugmentedActionContextRoot,
    payload: boolean
  ): void;

  [ROOT_STORE.ACTIONS.REDIRECT_ROUTE](
    { dispatch }: AugmentedActionContextRoot,
    payload: RouteLocationNormalized | undefined
  ): void;

  [ROOT_STORE.ACTIONS.BREADCRUMBS_ITEMS](
    { dispatch }: AugmentedActionContextRoot,
    payload: INavigationData[]
  ): void;

  [ROOT_STORE.ACTIONS.ACTIVE_THEME](
    { commit }: AugmentedActionContextRoot,
    payload: string
  ): void;

  [ROOT_STORE.ACTIONS.CLEAR_ALL]({ commit }: AugmentedActionContextRoot): void;

  [ROOT_STORE.ACTIONS.TOASTER_ADD](
    { commit }: AugmentedActionContextRoot,
    payload: IToast
  ): void;

  [ROOT_STORE.ACTIONS.TOASTER_REMOVE](
    { commit }: AugmentedActionContextRoot,
    payload: IToast
  ): void;
}
