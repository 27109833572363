import {
  RootGetters,
  RootMutations,
  RootActions,
} from "@/store/modules/root/enums";

export const ROOT_STORE = {
  GETTERS: RootGetters,
  MUTATIONS: RootMutations,
  ACTIONS: RootActions,
};
