<script lang="ts" setup>
import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  XCircleIcon,
} from "@heroicons/vue/outline";
import { XIcon } from "@heroicons/vue/solid";
import { PropType, defineProps, onMounted, ref, Ref, computed } from "vue";
import { IToast, ToastType } from "@/store/modules/root/interfaces";
import { useStore } from "@/use/useStore";
import { ROOT_STORE } from "@/store/modules/root/constants";

const props = defineProps({
  toast: {
    type: Object as PropType<IToast>,
    required: true,
  },
});

const store = useStore();
const visible = ref(true);
const autoHide: Ref<number | null> = ref(null);

const progressClass = computed(() => {
  switch (props.toast.type) {
    case ToastType.warning:
      return "bg-yellow-300";
    case ToastType.error:
      return "bg-red-300";
    case ToastType.success:
      return "bg-green-300";
    default:
      return "theme-bg-color";
  }
});

function removeToast() {
  setTimeout(() => {
    store.dispatch(ROOT_STORE.ACTIONS.TOASTER_REMOVE, props.toast);
  }, 700);
}
function close() {
  visible.value = false;
  removeToast();
}

function startAutoHide() {
  return setTimeout(() => {
    visible.value = false;
    removeToast();
  }, 4000);
}
function stopAutoHide() {
  clearTimeout(autoHide.value ?? 0);
}

onMounted(() => {
  autoHide.value = startAutoHide();
});
</script>
<template>
  <transition
    enter-active-class="transform ease-out duration-300 transition"
    enter-from-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
    enter-to-class="translate-y-0 opacity-100 sm:translate-x-0"
    leave-active-class="transition ease-in duration-300"
    leave-from-class="opacity-100"
    leave-to-class="opacity-0"
  >
    <div
      v-show="visible"
      class="toast max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden"
      @mouseover="stopAutoHide()"
      @mouseout="autoHide = startAutoHide()"
    >
      <div class="p-4">
        <div class="flex items-start">
          <div class="flex-shrink-0">
            <CheckCircleIcon
              class="h-6 w-6 text-green-400"
              aria-hidden="true"
              v-if="toast.type === ToastType.success"
            />
            <ExclamationCircleIcon
              class="h-6 w-6 text-yellow-400"
              aria-hidden="true"
              v-if="toast.type === ToastType.warning"
            />
            <XCircleIcon
              class="h-6 w-6 text-red-400"
              aria-hidden="true"
              v-if="toast.type === ToastType.error"
            />
          </div>
          <div class="ml-3 w-0 flex-1 pt-0.5">
            <p class="text-sm font-medium text-gray-900">{{ toast.title }}</p>
            <p class="mt-1 text-sm text-gray-500">
              {{ toast.message }}
            </p>
          </div>
          <div class="ml-4 flex-shrink-0 flex">
            <button
              @click="close"
              class="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500"
            >
              <span class="sr-only">Close</span>
              <XIcon class="h-5 w-5" aria-hidden="true" />
            </button>
          </div>
        </div>
      </div>
      <div class="progress h-0.5" :class="progressClass"></div>
    </div>
  </transition>
</template>
<style>
.progress {
  animation: myMove 5s ease;
}
.toast:hover .progress {
  animation: none;
  width: 100%;
}

@keyframes myMove {
  from {
    width: 100%;
  }
  to {
    width: 0;
  }
}
</style>
