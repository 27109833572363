import { createApp } from "vue";
import { createI18n } from "vue-i18n";
import App from "@/App.vue";
import { router } from "@/router";
import { store } from "@/store";
import "@/assets/main.css";
import { messageSchema, messages, defaultLocale } from "@/locales";
import { ROOT_STORE } from "@/store/modules/root/constants";
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import { createVfm } from 'vue-final-modal'

export const i18n = createI18n<[messageSchema], "pt-BR">({
  globalInjection: true,
  locale: defaultLocale,
  fallbackLocale: defaultLocale,
  messages,
});

const app = createApp(App);

app.config.errorHandler = (err: unknown, vm, info: string) => {
  // Verifique se err é do tipo Error antes de prosseguir
  if (err instanceof Error) {
    // Filtra os erros que você quer ignorar
    if (err.message.includes("exports is not defined")) {
      console.log("Erro ignorado:", err.message);
      return; // Ignorar o erro sem exibi-lo
    }

    // Se for outro erro, pode continuar com o fluxo padrão
    console.error("Erro não ignorado:", err);
  } else {
    // Lide com outros tipos de erros se necessário
    console.error("Erro desconhecido:", err);
  }
};
app.use(i18n);
app.use(router);
app.use(store);

const vfm = createVfm()

app.use(vfm);

app.component("VueDatePicker", VueDatePicker);

app.mount("#app");

store.dispatch(ROOT_STORE.ACTIONS.ACTIVE_THEME, window.location.host);
