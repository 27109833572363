import {
  UserGetters,
  UserMutations,
  UserActions,
} from "@/store/modules/user/enums";

export const USER_STORE = {
  GETTERS: UserGetters,
  MUTATIONS: UserMutations,
  ACTIONS: UserActions,
};
