export enum Status {
  RECORDING = 1,
  RECORDED = 2,
  PROCESSING_DOWNLOAD = 3,
  DOWNLOAD_AVAILABLE = 4,
  TJ_WAIT_PROCESSING = 5,
  TJ_SENT = 6,
}

export const IStatusLabel = {
  [Status.RECORDING]: "recording",
  [Status.RECORDED]: "recorded",
  [Status.PROCESSING_DOWNLOAD]: "processing download",
  [Status.DOWNLOAD_AVAILABLE]: "download available",
  [Status.TJ_WAIT_PROCESSING]: "tj wait processing",
  [Status.TJ_SENT]: "tj published",
};
