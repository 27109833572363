export enum RootGetters {
  SIDE_NAVIGATION_ITEMS = "SIDE_NAVIGATION_ITEMS",
  SIDE_NAVIGATION_OPEN = "SIDE_NAVIGATION_OPEN",
  REDIRECT_ROUTE = "REDIRECT_ROUTE",
  BREADCRUMBS_ITEMS = "BREADCRUMBS_ITEMS",
  THEME = "THEME",
  TOASTERS = "TOASTERS",
}
export enum RootMutations {
  SIDE_NAVIGATION_ITEMS = "SIDE_NAVIGATION_ITEMS",
  SIDE_NAVIGATION_OPEN = "SIDE_NAVIGATION_OPEN",
  REDIRECT_ROUTE = "REDIRECT_ROUTE",
  BREADCRUMBS_ITEMS = "BREADCRUMBS_ITEMS",
  THEME = "THEME",
  TOASTER_ADD = "TOASTER_ADD",
  TOASTER_REMOVE = "TOASTER_REMOVE",
}
export enum RootActions {
  SIDE_NAVIGATION_CURRENT = "SIDE_NAVIGATION_CURRENT",
  SIDE_NAVIGATION_ITEMS = "SIDE_NAVIGATION_ITEMS",
  SIDE_NAVIGATION_OPEN = "SIDE_NAVIGATION_OPEN",
  ACTIVE_THEME = "ACTIVE_THEME",
  CLEAR_ALL = "CLEAR_ALL",
  REDIRECT_ROUTE = "REDIRECT_ROUTE",
  BREADCRUMBS_ITEMS = "BREADCRUMBS_ITEMS",
  TOASTER_ADD = "TOASTER_ADD",
  TOASTER_REMOVE = "TOASTER_REMOVE",
}
