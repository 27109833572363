<script setup lang="ts">
import { computed, ref } from "vue";
import { useStore } from "@/use/useStore";
import { USER_STORE } from "@/store/modules/user/constants";
import EmptyTemplate from "@/views/templates/EmptyTemplate.vue";
import SpinnerUi from "@/components/ui/SpinnerUi.vue";
import { ROOT_STORE } from "@/store/modules/root/constants";

let email = ref("");
let password = ref("");

let loading = ref(false);
const store = useStore();

function login() {
  if (isEnableSendForm.value) {
    store.dispatch(USER_STORE.ACTIONS.LOGIN, {
      email: email.value,
      password: password.value,
    });
    loading.value = true;
  }
}

const isEnableSendForm = computed(
  () => email.value.length > 0 && password.value.length > 0
);

const theme = computed(() => store.getters[ROOT_STORE.GETTERS.THEME]);

const isFormLogin = ref(true);
function recoverPass() {
  store.dispatch(USER_STORE.ACTIONS.RECOVERY_PASS, {
    email: email.value,
  });
}
</script>
<template>
  <empty-template>
    <div
      class="min-h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8"
    >
      <div class="max-w-md w-full space-y-8">
        <div>
          <img
            class="mx-auto h-12 w-auto"
            :src="`/img/${theme.logo}`"
            alt="Workflow"
          />
          <h2 class="mt-6 text-center text-3xl font-extrabold text-gray-500">
            {{ $t("login.tittle") }}
          </h2>
          <p class="mt-2 text-center text-sm text-gray-500">
            {{ $t("login.noAccount") }}
            <a href="#" class="font-medium text-gray-500 hover:text-indigo-500">
              {{ $t("login.requestDemo") }}
            </a>
          </p>
        </div>
        <form
          class="mt-8 space-y-6"
          method="post"
          onsubmit="this.preventDefault"
        >
          <input type="hidden" name="remember" value="true" />
          <div class="rounded-md shadow-sm -space-y-px">
            <div>
              <label for="email-address" class="sr-only">
                {{ $t("form.email") }}
              </label>
              <input
                id="email-address"
                :name="$t('form.email')"
                type="email"
                autocomplete="email"
                v-model="email"
                @keyup.enter="login"
                required=""
                class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:z-10 sm:text-sm"
                :placeholder="$t('form.email')"
              />
            </div>
            <div v-if="isFormLogin">
              <label for="password" class="sr-only">
                {{ $t("form.password") }}
              </label>
              <input
                id="password"
                :name="$t('form.password')"
                type="password"
                v-model="password"
                autocomplete="current-password"
                required=""
                @keyup.enter="login"
                class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:z-10 sm:text-sm"
                :placeholder="$t('form.password')"
              />
            </div>
          </div>

          <div class="flex items-center justify-between" v-if="isFormLogin">
            <div class="flex items-center" v-show="false">
              <input
                id="remember-me"
                name="remember-me"
                type="checkbox"
                class="h-4 w-4 text-indigo-600 border-gray-300 rounded"
              />
              <label for="remember-me" class="ml-2 block text-sm text-gray-900">
                {{ $t("login.rememberMe") }}
              </label>
            </div>

            <div class="text-sm" v-if="false">
              <span
                @click="isFormLogin = false"
                class="font-medium text-indigo-600 hover:text-gray-700 cursor-pointer"
              >
                {{ $t("login.forgotPass") }}
              </span>
            </div>
          </div>
          <div v-if="isFormLogin">
            <button
              :class="
                isEnableSendForm
                  ? 'theme-bg-color hover:theme-bg-color-darker'
                  : 'bg-gray-400 cursor-default'
              "
              :disabled="!isEnableSendForm"
              type="button"
              @click="login"
              v-if="!loading"
              class="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white"
            >
              {{ $t("login.btSignIn") }}
            </button>
            <button
              v-if="loading"
              class="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-gray-400 theme-bg-color"
            >
              {{ $t("login.authenticating") }}
              <SpinnerUi class="h-5 w-5 float-left ml-3" style="color: white" />
            </button>
          </div>
          <div v-else class="flex flex-row gap-4">
            <button
              :class="
                email.length > 0
                  ? 'theme-bg-color hover:theme-bg-color-darker text-white'
                  : 'bg-gray-300 text-gray-200 cursor-default'
              "
              :disabled="email.length === 0"
              type="button"
              @click="recoverPass()"
              class="group w-1/2 py-2 px-4 border border-transparent text-sm font-medium rounded-md"
            >
              {{ $t("login.recoverPass") }}
            </button>
            <button
              @click="isFormLogin = true"
              class="group w-1/2 py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-gray-400"
            >
              {{ $t("button.back") }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </empty-template>
</template>
