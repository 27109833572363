import { Module } from "vuex";
import { IRootState } from "@/store/modules/root/interfaces";
import { IVideoPlayer } from "@/store/modules/videoPlayer/interfaces";
import { getters } from "@/store/modules/videoPlayer/getters";
import { actions } from "@/store/modules/videoPlayer/actions";
import { mutations } from "@/store/modules/videoPlayer/mutations";
import { state } from "@/store/modules/videoPlayer/state";

// Module
const videoPlayer: Module<IVideoPlayer, IRootState> = {
  state,
  getters,
  mutations,
  actions,
};

export default videoPlayer;
