export enum Feeling {
  Negative = "negative",
  Neutral = "neutral",
  Positive = "positive",
}

export enum Emotion {
  Anger = "anger",
  Boredom = "boredom",
  Empty = "empty",
  Enthusiasm = "enthusiasm",
  Fun = "fun",
  Happiness = "happiness",
  Hate = "hate",
  Love = "love",
  Neutral = "neutral",
  Relief = "relief",
  Sadness = "sadness",
  Surprise = "surprise",
  Worry = "worry",
}

export enum PersonaType {
  All = "ALL",
  Client = "CLIENT",
  Attendant = "ATTENDANT",
  Uninformed = "UNINFORMED",
}
