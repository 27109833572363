import type { AxiosInstance } from "axios";
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { apiErrorHandlers } from "@/service/utils";

class HTTPClient {
  protected baseURL =
    process.env.NODE_ENV === "production"
      ? "/api"
      : "http://localhost:3000/api";

  readonly http: AxiosInstance;

  constructor() {
    this.http = axios.create({
      baseURL: this.baseURL,
      headers: {},
    });

    this.http.interceptors.request.use((config) => {
      if (config.headers !== undefined) {
        config.headers.authorization =
          "Bearer " + localStorage.getItem("token") ?? "";
      }
      return config;
    }, apiErrorHandlers);
  }

  get<T = never, R = AxiosResponse<T>, D = never>(
    url: string,
    config?: AxiosRequestConfig<D>
  ): Promise<R> {
    return this.http.get(url, config);
  }

  post<T = never, R = AxiosResponse<T>, D = never>(
    url: string,
    data?: D,
    config?: AxiosRequestConfig<D>
  ): Promise<R> {
    return this.http.post(url, data, config);
  }
}

export const API = new HTTPClient();
