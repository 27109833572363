export enum VideoPlayerGetters {
  INSTANCE = "VIDEO_INSTANCE",
  PLAYER_READY = "VIDEO_PLAYER_READY",
  PLAYER_ERROR = "VIDEO_PLAYER_ERROR",
  SECONDS_DURATION = "VIDEO_SECONDS_DURATION",
  SECONDS_CURRENT = "VIDEO_SECONDS_CURRENT",
  LOADING_PERCENTAGE = "VIDEO_LOADING_PERCENTAGE",
}
export enum VideoPlayerMutations {
  SET_INSTANCE = "SET_INSTANCE",
  PLAYER_READY = "PLAYER_READY",
  SET_PLAYER_ERROR = "SET_PLAYER_ERROR",
  SET_SECONDS_DURATION = "SET_SECONDS_DURATION",
  SET_SECONDS_CURRENT = "SET_SECONDS_CURRENT",
  SET_LOADING_PERCENTAGE = "SET_LOADING_PERCENTAGE",
}
export enum VideoPlayerActions {
  INIT_INSTANCE = "VIDEO_INIT",
  PLAY = "VIDEO_PLAY",
  PLAY_SNIPPET = "VIDEO_PLAY_SNIPPET",
  PLAYER_READY = "VIDEO_PLAYER_READY",
}
