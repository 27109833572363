<script setup lang="ts">
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue";
import { BellIcon, MenuIcon, LogoutIcon } from "@heroicons/vue/outline";
import { OfficeBuildingIcon } from "@heroicons/vue/solid";
import { getNameInitials } from "@/service/utils";
import { useStore } from "@/use/useStore";
import { ROOT_STORE } from "@/store/modules/root/constants";
import { USER_STORE } from "@/store/modules/user/constants";
import { computed, ComputedRef } from "vue";
import { IUser } from "@/store/modules/user/interfaces";
import "@vuepic/vue-datepicker/dist/main.css";

const store = useStore();

const userNavigation = [];

function openMenu() {
  store.dispatch(ROOT_STORE.ACTIONS.SIDE_NAVIGATION_OPEN, true);
}

const user: ComputedRef<IUser> = computed(
  () => store.getters[USER_STORE.GETTERS.LOGGED_USER]
);

const userInitials = computed(() => {
  return getNameInitials(user.value.name);
});
</script>

<template>
  <div class="sticky top-0 z-10 flex-shrink-0 flex h-16 bg-white shadow">
    <button
      type="button"
      class="px-4 border-r border-gray-200 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 md:hidden"
      @click="openMenu()"
    >
      <span class="sr-only">Open sidebar</span>
      <MenuIcon class="h-6 w-6" aria-hidden="true" />
    </button>
    <div class="flex-1 px-4 flex justify-between">
      <div class="flex-1 flex align-middle gap-4" v-if="user">
        <div class="flex flex-row theme-color">
          <div class="inset-y-0 flex items-center pointer-events-none pr-2">
            <OfficeBuildingIcon class="h-5 w-5" aria-hidden="true" />
          </div>
          <div
            class="w-full h-full py-3 placeholder-gray-500 flex items-center pr-5 mr-5 border-r-2 border-slate-700"
          >
            <div class="inset-y-0 pointer-events-none">
              Comarca: {{ user.district }} Vara: {{ user.court }}
            </div>
          </div>
        </div>
      </div>
      <div class="ml-4 flex items-center md:ml-6">
        <button
          type="button"
          class="hidden bg-white p-1 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          <span class="sr-only">View notifications</span>
          <BellIcon class="h-6 w-6" aria-hidden="true" />
        </button>

        <!-- Profile dropdown -->
        <Menu as="div" class="ml-3 relative">
          <div>
            <MenuButton
              class="max-w-xs bg-white flex items-center text-sm rounded-full"
            >
              <span class="sr-only">Open user menu</span>
              <div
                class="theme-bg-color rounded-full w-8 h-8 flex items-center justify-center"
              >
                <span class="text-white" :title="user.name">
                  {{ userInitials }}
                </span>
              </div>
            </MenuButton>
          </div>
          <transition
            enter-active-class="transition ease-out duration-100"
            enter-from-class="transform opacity-0 scale-95"
            enter-to-class="transform opacity-100 scale-100"
            leave-active-class="transition ease-in duration-75"
            leave-from-class="transform opacity-100 scale-100"
            leave-to-class="transform opacity-0 scale-95"
          >
            <MenuItems
              class="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
            >
              <MenuItem
                v-for="item in userNavigation"
                :key="item.name"
                v-slot="{ active }"
              >
                <a
                  :href="item.href"
                  class="block px-4 py-2 text-sm text-gray-700"
                  :class="active ? 'bg-gray-100' : ''"
                >
                  {{ item.name }}
                </a>
              </MenuItem>
            </MenuItems>
          </transition>
        </Menu>

        <router-link
          :to="{ name: 'Logout' }"
          type="button"
          class="ml-2 bg-white inline-flex p-1 rounded-full text-gray-600 hover:text-gray-400 focus:outline-none"
        >
          {{ $t("logout") }}
          <logout-icon class="ml-2 h-6 w-6" aria-hidden="true" />
        </router-link>
      </div>
    </div>
  </div>
</template>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>
