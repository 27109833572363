import { ActionTree } from "vuex";
import { IRootState } from "@/store/modules/root/interfaces";
import {
  VideoPlayerActionsTypes,
  IVideoPlayer,
} from "@/store/modules/videoPlayer/interfaces";
import { VIDEO_PLAYER_STORE } from "@/store/modules/videoPlayer/constants";
import videojs from "video.js";

export const actions: ActionTree<IVideoPlayer, IRootState> &
  VideoPlayerActionsTypes = {
  [VIDEO_PLAYER_STORE.ACTIONS.INIT_INSTANCE](
    { commit },
    payload: { element: HTMLElement | string; fileUrl: string }
  ) {
    const instance = videojs(
      payload.element,
      {
        // autoplay: false,
        controls: true,
        // preload: 'auto',
        fluid: true,
        aspectRatio: "16:9",
        // muted: false,
        controlBar: {
          remainingTimeDisplay: false,
        },
        playbackRates: [1.0, 1.5, 2.0],
        techOrder: ["html5"],
        plugins: {},
        sources: [
          {
            src: payload.fileUrl,
            type: "video/mp4",
          },
        ],
      },
      () => {
        commit(VIDEO_PLAYER_STORE.MUTATIONS.PLAYER_READY, true);
      }
    );

    commit(VIDEO_PLAYER_STORE.MUTATIONS.SET_INSTANCE, instance);
  },
  [VIDEO_PLAYER_STORE.ACTIONS.PLAY]({ state }) {
    if (state.instance === null) {
      return;
    }
    state.instance.paused() ? state.instance.play() : state.instance.pause();
  },
  [VIDEO_PLAYER_STORE.ACTIONS.PLAY_SNIPPET]({ state }, payload: number) {
    if (state.instance === null) {
      return;
    }

    state.instance.currentTime(payload);
    state.instance.play();
  },
  [VIDEO_PLAYER_STORE.ACTIONS.PLAYER_READY]({ commit }, payload: boolean) {
    commit(VIDEO_PLAYER_STORE.MUTATIONS.PLAYER_READY, payload);
  },
};
