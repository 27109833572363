import { store } from "@/store";
import { USER_STORE } from "@/store/modules/user/constants";
import External from "@/router/external";
import router from "@/router/router";

const openRoutes = ["Login", ...External.map((item) => item.name)];

router.beforeEach(async (to, from, next) => {
  if (openRoutes.includes(to.name?.toString() ?? "")) {
    next();
  }

  if (!store.getters[USER_STORE.GETTERS.IS_LOGGED]) {
    if (localStorage.getItem("token") !== null) {
      await store.dispatch(USER_STORE.ACTIONS.CHECK);
      if (store.getters[USER_STORE.GETTERS.IS_LOGGED]) {
        next();
      }
    }
    next({ name: "Login" });
  }

  next();
});
