import { ActionTree } from "vuex";
import { IRootState } from "@/store/modules/root/interfaces";
import {
  AuthResponse,
  WebexAuthResponse,
  IUserStore,
  UserActionsTypes,
} from "@/store/modules/user/interfaces";
import { USER_STORE } from "@/store/modules/user/constants";
import { ROOT_STORE } from "@/store/modules/root/constants";
import { API } from "@/service/API";
import { apiErrorHandlers } from "@/service/utils";
import { router } from "@/router";
import { AxiosResponse } from "axios";
import { store } from "@/store";
import { IWebexMeeting, IWebexUser } from "@/interfaces/IWebex";

async function resolveAuth(response: AxiosResponse) {
  const user = response.data.user;

  store.commit(USER_STORE.MUTATIONS.LOGIN, {
    user: {user},
    token: response.data.token,
  });

  if (response.data.token !== undefined) {
    localStorage.setItem("token", response.data.token);
  }
}

export const actions: ActionTree<IUserStore, IRootState> & UserActionsTypes = {
  async [USER_STORE.ACTIONS.LOGIN](
    any,
    payload: {
      email: string;
      password: string;
    }
  ) {
    await API.http
      .post<AuthResponse>("auth/login", payload)
      .then(resolveAuth)
      .catch(apiErrorHandlers);

    router.push({ name: "Dashboard" }).then();
  },
  async [USER_STORE.ACTIONS.RECOVERY_PASS](
    any,
    payload: {
      email: string;
    }
  ) {
    // TODO implementar um método para recuperação de senha
    console.log(any, payload);
  },
  async [USER_STORE.ACTIONS.WEBEX_PASS_THROUGH](
    { state },
    payload: { user: IWebexUser; meeting: IWebexMeeting }
  ) {
/*     await API.http
      .post<WebexAuthResponse>("recall/saveMeeting", { meeting: payload.meeting })
      .then((response) => {
        console.log(response);
      })
      .catch((err) => {
        console.log(err);
      }); */
    await API.http
      .post<WebexAuthResponse>("auth/webex", { user: { id: payload.user.id, orgId: payload.user.orgId, email: payload.user.email, displayName: payload.user.displayName, token: payload.user.token }})
      .then((response) => {
        const user = response.data.user;

        const updatedUser = Object.assign({}, user, { meeting: payload.meeting });
        console.log(user);
        
        store.commit(USER_STORE.MUTATIONS.LOGIN, {
          user: {
            user: updatedUser,
            token: response.data.token,
          },
          meeting: payload.meeting,
        });

        if (response.data.token !== undefined) {
          localStorage.setItem("token", response.data.token);
        }

        if (state.user !== undefined) {
          router
            .push({
              name: "AudienceRecorder",
            })
            .then();
        } else {
          router.push({ name: "Login" }).then();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  },
  async [USER_STORE.ACTIONS.CHECK]() {
    return API.http
      .get<AuthResponse>("auth/check")
      .then(resolveAuth)
      .catch(apiErrorHandlers);
  },
  async [USER_STORE.ACTIONS.LOGOUT]({ commit, dispatch }) {
    commit(USER_STORE.MUTATIONS.LOGOUT, undefined);
    API.http
      .post("auth/logout")
      .then(() => {
        localStorage.removeItem("token");
        dispatch(ROOT_STORE.ACTIONS.CLEAR_ALL);
      })
      .then(() => router.push("/login"));
  },
};
