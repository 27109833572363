import { IRootState } from "@/store/modules/root/interfaces";

export const state: IRootState = {
  sideNavigationItems: [],
  sideNavigationOpen: false,
  breadcrumbsItems: [],
  theme: {
    name: "theme-onni",
    logo: "logo-onni.svg",
  },
  toasters: [],
};
