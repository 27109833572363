import { GetterTree } from "vuex";
import { IRootState } from "@/store/modules/root/interfaces";
import {
  IUserGettersTypes,
  IUserStore,
  IUser,
} from "@/store/modules/user/interfaces";
import { USER_STORE } from "@/store/modules/user/constants";
import { IWebexMeeting, IWebexUser } from "@/interfaces/IWebex";

export const getters: GetterTree<IUserStore, IRootState> & IUserGettersTypes = {
  [USER_STORE.GETTERS.USER]: (state: IUserStore): IUser | undefined => {
    return state.user;
  },
  [USER_STORE.GETTERS.LOGGED_USER]: (state: IUserStore): IUser => {
    return <IUser>state.user;
  },
  [USER_STORE.GETTERS.MEETING]: (state: IUserStore): IWebexMeeting => {
    return <IWebexMeeting>state.meeting;
  },
  [USER_STORE.GETTERS.IS_LOGGED]: (state: IUserStore): boolean => {
    return state.user !== undefined;
  },
};
