<script lang="ts" setup>
import ToastItem from "@/components/layout/ToastItem.vue";
import { computed } from "vue";
import { ROOT_STORE } from "@/store/modules/root/constants";
import { useStore } from "@/use/useStore";

const store = useStore();

const toasters = computed(() => store.getters[ROOT_STORE.GETTERS.TOASTERS]);
</script>
<template>
  <div
    v-if="toasters.length"
    aria-live="assertive"
    class="fixed inset-0 flex items-end px-4 py-10 pointer-events-none sm:p-6 sm:items-start z-20"
  >
    <div class="w-full flex flex-col items-center space-y-4 sm:items-end">
      <template v-for="(toast, index) in toasters">
        <ToastItem
          v-if="toast !== undefined"
          :toast="toast"
          :key="`notificationToast${index}`"
        />
      </template>
    </div>
  </div>
</template>
