import { Locales } from "@/locales/locales";

import enUS from "@/locales/en-US.json";
import ptBR from "@/locales/pt-BR.json";

export type messageSchema = typeof ptBR;

export const messages = {
  [Locales.enUS]: enUS,
  [Locales.ptBR]: ptBR,
};

export const defaultLocale = Locales.ptBR;
