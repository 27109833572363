import { Feeling } from "@/enums/Commons";
import { store } from "@/store";
import { ROOT_STORE } from "@/store/modules/root/constants";
import { ToastType } from "@/store/modules/root/interfaces";
import { USER_STORE } from "@/store/modules/user/constants";
import { router } from "@/router";
import { i18n } from "@/main";

export function formatSecToTime(seconds: number): string {
  let secs = Math.floor(seconds);
  let minutes = Math.floor(secs / 60);
  const hours = Math.floor(minutes / 60);

  secs %= 60;
  minutes %= 60;

  let strHours = "";
  if (hours) {
    strHours = `${("0" + hours).slice(-2)}:`;
  }

  return `${strHours}${("0" + minutes).slice(-2)}:${("0" + secs).slice(-2)}`;
}

export function getFeelingColor(feeling: string): string {
  switch (feeling) {
    case Feeling.Positive:
      return "text-green-500";
    case Feeling.Negative:
      return "text-red-600";
    default:
      return "text-gray-400";
  }
}

export function getNameInitials(name: string): string {
  const text = name.split(/[ ,]+/);

  if (text.length > 1) {
    return text[0].slice(0, 1) + text[1].slice(0, 1);
  }
  return text[0].slice(0, 2);
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
export async function apiErrorHandlers(error: any): Promise<void> {
  if (error.response === undefined) {
    console.log(error);
    return;
  }

  if (error.response.status === 401 || error.response.status === 403) {
    await store.dispatch(ROOT_STORE.ACTIONS.TOASTER_ADD, {
      id: `${Date.now()}`,
      title: i18n.global.t("error"),
      message: i18n.global.t(error.response.data.message),
      type: ToastType.error,
    });

    store.commit(USER_STORE.MUTATIONS.LOGOUT, undefined);
    localStorage.removeItem("token");
    router.push("/login").then();
  } else {
    await store.dispatch(ROOT_STORE.ACTIONS.TOASTER_ADD, {
      id: `${Date.now()}`,
      title: i18n.global.t("errorCode", { code: error.response.status }),
      message:
        error.response.data === undefined
          ? i18n.global.t("unexpectedError")
          : error.response.data,
      type: ToastType.error,
    });
  }
}

export function setFavicons(image: string): void {
  const headTitle = document.querySelector("head");

  const favIcons = [
    { rel: "apple-touch-icon" },
    { rel: "apple-touch-startup-image" },
    { rel: "shortcut icon" },
  ];

  favIcons.forEach((favIcon) => {
    const icon = document.createElement("link");
    icon.setAttribute("rel", favIcon.rel);
    icon.setAttribute("href", image);
    headTitle?.appendChild(icon);
  });
}
