import {
  VideoPlayerGetters,
  VideoPlayerMutations,
  VideoPlayerActions,
} from "@/store/modules/videoPlayer/enums";

export const VIDEO_PLAYER_STORE = {
  GETTERS: VideoPlayerGetters,
  MUTATIONS: VideoPlayerMutations,
  ACTIONS: VideoPlayerActions,
};
