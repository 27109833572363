import { IVideoPlayer } from "@/store/modules/videoPlayer/interfaces";

export const state: IVideoPlayer = {
  instance: null,
  playerReady: false,
  playerError: null,
  secondsDuration: 0,
  secondsCurrent: 0,
  loadingPercentage: 0,
};
