import { ActionTree } from "vuex";
import {
  RootActionsTypes,
  IRootState,
  INavigationData,
  IToast,
} from "@/store/modules/root/interfaces";
import { ROOT_STORE } from "@/store/modules/root/constants";
import { RouteLocationNormalized } from "vue-router";
import { setFavicons } from "@/service/utils";

export const actions: ActionTree<IRootState, IRootState> & RootActionsTypes = {
  [ROOT_STORE.ACTIONS.SIDE_NAVIGATION_CURRENT]({ getters }, payload) {
    getters[ROOT_STORE.GETTERS.SIDE_NAVIGATION_ITEMS].forEach(
      (item: INavigationData) => {
        item.current = payload !== undefined && item.slug === payload;
        item.children?.forEach(
          (child) =>
            (child.current = payload !== undefined && child.slug === payload)
        );
      }
    );
  },
  [ROOT_STORE.ACTIONS.SIDE_NAVIGATION_OPEN]: (
    { commit },
    payload: boolean
  ): void => {
    commit(ROOT_STORE.MUTATIONS.SIDE_NAVIGATION_OPEN, payload);
  },
  [ROOT_STORE.ACTIONS.REDIRECT_ROUTE]: (
    { commit },
    payload: RouteLocationNormalized | undefined
  ): void => {
    commit(ROOT_STORE.MUTATIONS.REDIRECT_ROUTE, payload);
  },
  [ROOT_STORE.ACTIONS.BREADCRUMBS_ITEMS]: (
    { commit },
    payload: INavigationData[]
  ): void => {
    commit(ROOT_STORE.MUTATIONS.BREADCRUMBS_ITEMS, payload);
  },
  [ROOT_STORE.ACTIONS.ACTIVE_THEME]({ commit }, payload) {
    const domain = payload.split(".");
    const theme = {
      name: `theme-${domain[0]}`,
      logo: "",
    };

    switch (domain[0]) {
      case "gppneus":
        theme.logo = "logo-gppneus.png";
        break;
      case "mobcall":
        theme.name = "theme-mobcall";
        theme.logo = "logo-mob.svg";
        break;
      default:
        theme.name = "theme-reach";
        theme.logo = "logo-onni.png";
        break;
    }
    setFavicons(`/ico/${theme.name}.ico`);
    commit(ROOT_STORE.MUTATIONS.THEME, theme);
  },
  [ROOT_STORE.ACTIONS.CLEAR_ALL]({ commit }) {
    commit(ROOT_STORE.MUTATIONS.BREADCRUMBS_ITEMS, []);
    commit(ROOT_STORE.MUTATIONS.SIDE_NAVIGATION_ITEMS, []);
    commit(ROOT_STORE.MUTATIONS.REDIRECT_ROUTE, undefined);
  },
  [ROOT_STORE.ACTIONS.TOASTER_ADD]({ commit }, toast: IToast) {
    commit(ROOT_STORE.MUTATIONS.TOASTER_ADD, toast);
  },
  [ROOT_STORE.ACTIONS.TOASTER_REMOVE]({ commit }, toast: IToast) {
    commit(ROOT_STORE.MUTATIONS.TOASTER_REMOVE, toast);
  },
};
