<template>
  <VueFinalModal
    class="flex justify-center items-center"
    content-class="p-4 bg-white dark:bg-black border border-gray-100 dark:border-gray-800 rounded-xl shadow-lg"
    @update:model-value="val => emit('update:modelValue', val)"
  >
    <div class="modal-content w-full max-w-xl">
      <div class="flex justify-end">
        <button @click="emit('update:modelValue', false)" class="close-button">
          <Icon icon="clarity:window-close-line" class="w-10 h-10 text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200" />
        </button>
      </div>
      <h3 class="text-lg font-semibold mb-4">{{ title }}</h3>
      <div class="mb-4">
        <p><strong>ID:</strong> {{ transcription.id }}</p>
        <p><strong>Email:</strong> {{ transcription.email }}</p>
        <p><strong>Meeting ID:</strong> {{ transcription.meetingId }}</p>
        <p><strong>Data de Criação:</strong> {{ formattedDate() }}</p>
        <p><strong>Duração:</strong> {{ transcription.duration }}</p>
      </div>
      <hr class="my-4 border-gray-300 dark:border-gray-700" />
      <p class="font-semibold mb-2">Frases:</p>
      <ul class="list-disc pl-6">
        <li
          v-for="frase in transcription.transcription.frases"
          :key="frase.inicio"
          class="border border-gray-300 dark:border-gray-700 rounded-md p-2 mb-2"
        >
          Participante: X<br> Início: {{ frase.inicio }}<br>
          Fim: {{ frase.fim }}<br>
          Texto: {{ frase.texto }}<br>
        </li>
      </ul>
    </div>
  </VueFinalModal>
</template>

<script setup lang="ts">
import { defineProps, defineEmits } from 'vue';
import { ITranscription } from '@/interfaces/IAudience';
import { VueFinalModal } from 'vue-final-modal';
import { Icon } from '@iconify/vue';

const props = defineProps<{
  transcription: ITranscription;
  title?: string;
}>();

const emit = defineEmits<{
  (e: 'update:modelValue', modelValue: boolean): void
}>()
const formattedDate = () => {
  const date = new Date(props.transcription.created_at);
  return date.toLocaleDateString('pt-BR');
};
</script>

<style scoped>
.VueFinalModal {
  /* Tornar o modal visível */
  opacity: 1 !important;
  /* Centralizar verticalmente */
  top: 50%;
  transform: translateY(-50%);
}

.modal-content {
  max-height: 400px; overflow-y: auto;
  position: relative;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  cursor: pointer;
}

/* Adicionando margem interna ao redor do conteúdo */
.modal-content > *:not(.flex) {
  margin-bottom: 1rem;
}

/* Estilos para a linha horizontal */
hr {
  border-width: 1px;
}

/* Estilos para a lista de frases */
ul {
  list-style-type: disc;
}
.purple-border {
  border: 2px solid #8a4cff; /* Cor roxa moderna */
  transition: border-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out; /* Transição suave */
}

.purple-border:hover {
  border-color: #6d28d9; /* Cor roxa mais escura ao passar o mouse */
}

.modal-content {
  border: 2px solid #8a4cff; /* Cor roxa moderna */
}
.modal-content {
  scrollbar-width: thin; /* Largura da barra de rolagem */
  scrollbar-color: #8a4cff transparent; /* Cor da barra e do fundo */
}

.modal-content::-webkit-scrollbar {
  width: 8px; /* Largura da barra de rolagem */
  border-radius: 10px; /* Cantos arredondados */
}

.modal-content::-webkit-scrollbar-track {
  background-color: transparent; /* Cor de fundo da área da barra de rolagem */
}

.modal-content::-webkit-scrollbar-thumb {
  background-color: #8a4cff; /* Cor da barra de rolagem */
  border-radius: 10px; /* Cantos arredondados */
}

.modal-content {
  border-radius: 15px; /* Borda arredondada */
  padding: 20px; /* Espaçamento interno */
}

.VueFinalModal {
  max-width: 90%; /* Largura máxima do modal */
}

/* Estilos adicionais para o VueFinalModal */
.VueFinalModal__wrapper {
  display: flex;
}
</style>
