<script setup lang="ts">
import AppTemplate from "@/views/templates/AppTemplate.vue";
import PanelUi from "@/components/ui/PanelUi.vue";
import { computed, defineProps, onMounted, ref, Ref } from "vue";
import moment from "moment";
import SpinnerUi from "@/components/ui/SpinnerUi.vue";
import { PlayIcon, DownloadIcon, ArrowLeftIcon } from "@heroicons/vue/solid";
import { API } from "@/service/API";
import { apiErrorHandlers } from "@/service/utils";
import {
  RecordsResponse,
  IRecord,
  IAudience,
  IAudienceSearch,
  RecordResponse,
} from "@/interfaces/IAudience";
import { IStatusLabel } from "@/enums/AudienceRecord";

const props = defineProps({
  id: {
    type: String,
    required: true,
  },
});

let loadingPlayer = ref(true);
function playRecord(idx: number) {
  loadingPlayer.value = true;
  records.value?.forEach((item) => (item.onPlay = false));
  if (idx >= 0) {
    records.value[idx].onPlay = true;
  }
}
const selectedRecord = computed(() =>
  records.value?.find((item) => item.onPlay)
);

const records: Ref<IRecord[]> = ref([]);
async function getRecords() {
  await API.http
    .get<RecordsResponse>(`audiences/${props.id}/records`)
    .then((response) => {
      records.value = response.data.records;
      let idx = records.value?.findIndex((record) => record.video_id !== "");
      if (idx >= 0) {
        records.value[idx].onPlay = true;
      }
    })
    .catch(apiErrorHandlers);
}

const audience: Ref<IAudience | undefined> = ref();
async function getAudience() {
  await API.http
    .get<IAudienceSearch>(`audiences/${props.id}`)
    .then((response) => {
      audience.value = response.data.audience;
      audience.value?.participants.push({
        cod: 0,
        name: "Nova parte",
        description: "",
        type: "P",
      });
    })
    .catch(apiErrorHandlers);
}

async function generateLink(record: IRecord) {
  record.download_url = "generating";
  await API.http
    .get<RecordResponse>(
      `audiences/${props.id}/records/${record.id}/generate-link`
    )
    .then((response) => {
      record.download_url = response.data.record.download_url;
      getRecords();
    })
    .catch(apiErrorHandlers);
}

onMounted(() => {
  getAudience();
  getRecords();
});
</script>
<template>
  <app-template>
    <div
      class="w-container mx-auto max-w-7xl flex flex-row-reverse p-2 sm:pt-4 gap-4"
    >
      <router-link
        class="flex p-2 sm:px-4 text-sm font-medium theme-color"
        :to="{
          name: 'Audiences',
        }"
      >
        <arrow-left-icon class="h-6 w-6 mr-2 sm:h-5 sm:w-5" />
        {{ $t("button.back") }}
      </router-link>
    </div>
    <div
      class="w-container mx-auto max-w-7xl flex flex-col lg:flex-row p-2 sm:pt-4 gap-4 md:px-5 md:gap-5 mb-3"
    >
      <panel-ui
        class="bg-gray-200 w-full lg:w-2/6"
        content-class="relative p-4 md:p-3"
        :title="
          selectedRecord !== undefined
            ? `${selectedRecord.audience} - ${selectedRecord.manual_part}`
            : ''
        "
      >
        <template v-if="selectedRecord">
          <div
            v-if="loadingPlayer"
            class="absolute inset-0 flex items-center justify-center"
            style="background-color: rgba(255, 255, 255, 0.5)"
          >
            <div
              class="w-auto bg-gray-200 py-2 px-3 flex items-center justify-center rounded-md"
            >
              <spinner-ui
                class="h-8 w-8 float-left mr-3 theme-color"
              ></spinner-ui>
              <h2 class="text-gray-700">{{ $t("loading") }}</h2>
            </div>
          </div>
          <iframe
            class="ring-0 border-0 h-full w-full"
            :src="`https://video.ibm.com/embed/recorded/${selectedRecord?.video_id}`"
            style="border: 0"
            allowfullscreen
            @load="loadingPlayer = false"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </template>
        <div
          v-else
          style="width: 100%"
          class="bg-gray-100 w-full h-full flex items-center justify-center rounded-md"
        >
          <p class="text-center text-gray-600 py-4 rounded-md">
            {{ $t("audience.noRecords") }}
          </p>
        </div>
      </panel-ui>
      <panel-ui
        class="bg-gray-200 w-full lg:w-4/6 mb-10"
        contentClass="p-3 overflow-auto"
        title="Cortes"
      >
        <table
          class="sm:table w-full flex flex-row sm:flex-col flex-nowrap text-sm"
        >
          <thead class="text-gray-600 sm:w-full">
            <tr
              v-for="(record, idx) in records"
              :key="`trRecord${record.id}`"
              class="bg-gray-50 flex flex-col flex-nowrap rounded-l-lg sm:rounded-none mb-2 sm:mb-0"
              :class="idx === 0 ? 'sm:table-row' : 'sm:hidden'"
            >
              <th
                class="table-cell p-2 text-left border-b-2 border-t sm:border-b-0 border-color-gray-300 rounded-lt-lg sm:rounded-none"
              >
                {{ $t("audience.table.start_at") }}
              </th>
              <th class="table-cell p-2 text-left border-b-2 sm:border-b-0">
                {{ $t("audience.table.audience") }}
              </th>
              <th class="table-cell p-2 text-left border-b-2 sm:border-b-0">
                {{ $t("audience.table.manualPart") }}
              </th>
              <th class="table-cell p-2 text-left border-b-2 sm:border-b-0">
                {{ $t("audience.table.duration") }}
              </th>
              <th class="table-cell p-2 text-left border-b-2 sm:border-b-0">
                {{ $t("audience.table.status") }}
              </th>
              <th
                class="table-cell p-2 text-left border-b sm:border-b-0 rounded-bl-lg sm:rounded-none"
              >
                &nbsp;
              </th>
            </tr>
          </thead>
          <tbody
            v-if="records !== undefined && records.length > 0"
            class="flex-1 sm:flex-none w-full text-gray-700"
          >
            <tr
              v-for="(record, idx) in records"
              :key="`record${record.id}`"
              :class="idx % 2 === 0 ? 'bg-white' : 'bg-gray-50'"
              class="flex flex-col flex-nowrap sm:table-row mb-2 sm:mb-0 rounded-r-lg sm:rounded-none"
            >
              <td
                class="border-gray-200 border p-2 sm:border-none rounded-tr-lg sm:rounded-none"
              >
                {{ moment(record.created_at).format("HH:mm") }}
              </td>
              <td class="border-gray-200 border p-2 sm:border-none">
                {{ record.audience !== "" ? record.audience : "&nbsp;" }}
              </td>
              <td class="border-gray-200 border p-2 sm:border-none truncate">
                {{ record.manual_part !== "" ? record.manual_part : "&nbsp;" }}
              </td>
              <td class="border-gray-200 border p-2 sm:border-none">
                {{ record.duration }}
              </td>
              <td class="border-gray-200 border p-2 sm:border-none">
                {{
                  $t(`audience.record.status.${IStatusLabel[record.status]}`)
                }}
              </td>
              <td
                class="border-gray-200 border p-2 sm:border-none flex flex-row gap-3 rounded-br-lg sm:rounded-none"
              >
                <template v-if="!record.onPlay && record.video_id !== ''">
                  <play-icon
                    @click="playRecord(idx)"
                    class="w-5 h-5 lg:w-6 lg:h-6 theme-color cursor-pointer hover:theme-color-darker"
                  />
                </template>
                <template v-else>
                  <play-icon class="w-5 h-5 lg:w-6 lg:h-6 text-gray-400" />
                </template>
                <a
                  v-if="
                    record.download_url !== null &&
                    record.download_url !== 'generating'
                  "
                  :href="record.download_url"
                  target="_blank"
                  class="theme-color cursor-pointer hover:theme-color-darker"
                >
                  <download-icon class="w-5 h-5 lg:w-6 lg:h-6" />
                </a>
                <a
                  v-if="record.download_url === null"
                  @click="generateLink(record)"
                  class="theme-color cursor-pointer hover:theme-color-darker text-center py-1"
                >
                  Gerar Link
                </a>
                <span class="pt-1" v-if="record.download_url === 'generating'">
                  <spinner-ui class="w-5 h-5 lg:w-6 lg:h-6 theme-color" />
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </panel-ui>
    </div>
  </app-template>
</template>
