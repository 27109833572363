<script setup lang="ts">
import { computed, defineProps, defineEmits, PropType } from "vue";
import { IPagination } from "@/interfaces/Commons";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/vue/solid";

const props = defineProps({
  pagination: {
    type: Object as PropType<IPagination>,
    required: true,
  },
});

const pages = computed(() => {
  let response: number[] = [];
  let current = props.pagination.current;

  let start = current - 2 <= 0 ? 1 : current - 2;
  let end =
    current + 2 < props.pagination.total ? current + 2 : props.pagination.total;

  const diff = end - start;
  if (diff < 4 && end + (4 - diff) < props.pagination.total) {
    end = end + (4 - diff);
  } else if (diff < 4 && start - (4 - diff) > 0) {
    start = start - (4 - diff);
  }

  for (let i = start; i <= end; i++) {
    response.push(i);
  }

  return response;
});

const startPage = computed(() => {
  if (props.pagination.current <= 1) {
    return 1;
  }

  if (props.pagination.countItems < props.pagination.size) {
    return props.pagination.countItems;
  }

  return (
    props.pagination.current * props.pagination.size - props.pagination.size
  );
});

const emit = defineEmits(["update:modelValue"]);

function goTo(page: number) {
  if (props.pagination.current !== page) {
    emit("update:modelValue", page);
  }
}
</script>
<template>
  <div
    class="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6 w-full shadow-sm ring-1 ring-black ring-opacity-5 md:rounded-md"
  >
    <div class="flex-1 flex items-center justify-between">
      <div>
        <p class="text-sm text-gray-700" v-if="pagination.total > 1">
          {{
            $t("pagination.label", {
              start: startPage,
              end: pagination.current * pagination.size,
              total: pagination.countItems,
            })
          }}
        </p>
        <p class="text-sm text-gray-700" v-if="pagination.total === 1">
          {{
            $t("pagination.labelOnePage", {
              total: pagination.countItems,
            })
          }}
        </p>
      </div>
      <div>
        <nav
          class="relative z-0 inline-flex rounded-md shadow-sm -space-x-px"
          aria-label="Pagination"
        >
          <span
            @click="goTo(pagination.current - 1)"
            v-if="pagination.total > 1"
            class="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 cursor-pointer"
          >
            <span class="sr-only">Previous</span>
            <ChevronLeftIcon class="h-5 w-5" aria-hidden="true" />
          </span>
          <template v-for="page in pages" :key="`page${page}`">
            <span
              @click="goTo(page)"
              aria-current="page"
              class="relative inline-flex items-center px-4 py-2 border text-sm font-medium"
              :class="
                page === pagination.current
                  ? 'theme-border-color theme-color z-40'
                  : 'border-gray-300 text-gray-500 hover:bg-gray-50 cursor-pointer z-10'
              "
            >
              {{ page }}
            </span>
          </template>
          <span
            @click="goTo(pagination.current + 1)"
            v-if="pagination.total > 1"
            class="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 cursor-pointer"
          >
            <span class="sr-only">Next</span>
            <ChevronRightIcon class="h-5 w-5" aria-hidden="true" />
          </span>
        </nav>
      </div>
    </div>
  </div>
</template>
