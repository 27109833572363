export enum Status {
  WAITING = 1,
  IN_PROGRESS = 2,
  PUBLISHED = 3,
}

export const IStatusLabel = {
  [Status.WAITING]: "waiting",
  [Status.IN_PROGRESS]: "in progress",
  [Status.PUBLISHED]: "published",
};
